import { Controller } from '@hotwired/stimulus';

/**
 * input--character-filter-controller: disallow certain characters (e.g. line breaks).
 */
export default class extends Controller {
  static values = {
    regex: String,
  };

  onchange() {
    const regex = new RegExp(this.regexValue, 'g');
    this.element.value = this.element.value.replace(regex, '');
  }
}
