import MetricsPublisher from './metrics_publisher';

// Publishes the metrics to plausible as a custom event.
//
// In order to be shown on the plausible dashboard, the name of the metric must be registerd as a
// custom goal in plausible. In addition, if the dimensions associated with the metric must be
// registered as custom properties.
//
// Only metrics that have the plausible property set to true will be published (since metrics like
// timers don't make a lot of sense in Plausible)
//
// See https://plausible.io/docs/custom-event-goals
export default class PlausiblePublisher extends MetricsPublisher {
  send() {
    // in the test env don't report to plausible
    if (!window.plausible) return;

    // publish to plausible: https://plausible.io/docs/custom-props/for-custom-events
    this.data.metrics.forEach((metric) => {
      if (metric.plausible === true) {
        // publish with dimensions as custom property for the event
        const dimensions = new Map(
          metric.dimensions.map((dim) => [dim, metric.value]),
        );
        window.plausible(metric.name, {
          props: Object.fromEntries(dimensions),
        });
      }
    });
  }
}
