// Parent class for all metric subclasses (Counter for now)
export default class Metric {
  value = undefined;

  constructor(name) {
    this.name = name;
    this.dimensions = {};
  }

  withValue(value) {
    this.value = value;

    return this;
  }

  withDimension(key, value) {
    if (
      key !== undefined &&
      key !== null &&
      key.trim().length > 0 &&
      value !== undefined &&
      value !== null &&
      value.toString().trim().length > 0
    ) {
      this.dimensions[key] = value;
    }

    return this;
  }

  withoutDimension(key) {
    delete this.dimensions[key];

    return this;
  }

  // publish this metric to plausible as well
  withPlausible() {
    this.plausible = true;

    return this;
  }
}
