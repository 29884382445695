import CancelledFormController from './cancelled_form_controller';

/**
 * csm--compose-posting-form controller: Extends the behaviour of the cancelled
 * form controller by collecting metrics specific to the compose posting form.
 * This includes monitoring of the visibility of the hide-email-author content,
 * so the metric dimension gets updated accordingly
 *
 * Also, records a metric about which post category was selected to infer whether
 * members have more issues with one category over another
 */
export default class extends CancelledFormController {
  userEmailContainer() {
    return $('div[data-posts--form-target="showUserEmailContainer"]');
  }

  postCategorySelect() {
    return $('#post_category_posts_attributes_0_category_id');
  }

  getPricacyDimensionValue(canOptOut) {
    return canOptOut ? 'EmailPrivacyEnabled' : 'EmailPrivacyDisabled';
  }

  updatePrivacyDimension() {
    const canOptOut = this.userEmailContainer().is(':visible');

    // replace dimension with accurate value
    this.formCounter.withDimension(
      'PrivacyType',
      this.getPricacyDimensionValue(canOptOut),
    );
  }

  connect() {
    super.connect();

    // capture category as dimension of the form counter
    this.formCounter.withDimension('SelectedCategory', 'None');
    this.postCategorySelect().on('change', (event) => {
      this.formCounter.withDimension(
        'SelectedCategory',
        `CategoryID_${event.target.value}`,
      );
    });

    $(document).on('csm:before-publish', () => {
      this.updatePrivacyDimension();
    });
  }

  disconnect() {
    super.disconnect();

    // Detach event handlers
    $(document).off('csm:before-publish');
    this.postCategorySelect.off('change');
  }
}
