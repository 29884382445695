import Metric from './metric';

export default class Timer extends Metric {
  lastStartDate = null;

  constructor(counterName) {
    super(counterName);
    this.value = 0;

    // Stop potentially open timer before publishing time
    // e.g. when clicking on a link after focussing on a field
    $(document).on('csm:before-publish', () => {
      this.stop();
    });
  }

  start() {
    if (this.lastStartDate === null) {
      this.lastStartDate = new Date();
    }
  }

  stop() {
    if (this.lastStartDate !== null) {
      this.value += new Date() - this.lastStartDate;
      this.lastStartDate = null;
    }
  }
}
