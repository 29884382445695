import Counter from './counter';
import Timer from './timer';
import MetricData from './metric_data';
import MetricsPublisher from './publishers/metrics_publisher';
import FpfPublisher from './publishers/fpf_publisher';
import PlausiblePublisher from './publishers/plausible_publisher';

/**
 * Main class used to create and publish clientside metrics (CSM).
 * It collects a set of metrics while a user is interacting with
 * a page and publishes them when the user exits from the page.
 */
export default class ClientSideMetrics {
  constructor() {
    this.data = new MetricData();
    this.metricPublishers = [];
  }

  withMetricsPublisher(publisher) {
    if (!(publisher instanceof MetricsPublisher)) {
      throw new Error('Object is not a MetricsPublisher instance');
    }

    if (this.metricPublishers.includes(publisher)) {
      return this;
    }

    this.metricPublishers.push(publisher);

    return this;
  }

  // Creates a new counter metric
  addCounter(metricName) {
    return this.data.addMetric(new Counter(metricName));
  }

  // Creates a new timer metric
  addTimer(metricName) {
    return this.data.addMetric(new Timer(metricName));
  }

  // publishes the collected metrics to the configured publisher
  // and then clears the metrics.
  publish() {
    $(document).trigger('csm:before-publish', this);

    if (this.data.isEmpty()) {
      return;
    }
    // send a copy of the CSM data to each registered publisher
    // to transform and send the metrics
    this.metricPublishers.forEach((publisher) => {
      publisher.publish(this.data.clone());
    });

    this.data = new MetricData();
  }
}
// register global instance and events handlers to publish clientside metrics
// before unload or turbo renders a new page
export const csm = new ClientSideMetrics()
  .withMetricsPublisher(new FpfPublisher())
  .withMetricsPublisher(new PlausiblePublisher());

// use pre-render instead of click so it also works on back/forward navigation
$(document).on('turbo:before-render', () => {
  csm.publish();
});
$(window).on('beforeunload', () => {
  csm.publish();
});
