import { camelCase } from 'lodash/string';
import MetricFormController from './metric_form_controller';

/**
 * csm--cancelled-form controller: checks whether a form has been cancelled (aka abandoned)
 * and publishes a metric in this case. If the form has been cancelled after an interaction
 * then the metric name will be published with an "AfterInteraction" appendix
 *
 * Interaction is defined as the user has at least clicked on one field in the form (versus
 * the field value has changed, e.g. text has been entered - if we ever want this it would
 * be easy to change or to collect this as a separate metric)
 *
 * Metrics collected for name "newPostCancellation":
 * - newPostCancellation: form was left. Dimensions:
 *   - InteractionType: NoInteraction, WithInteraction
 *   - LastElement: last interacted form element
 *   - SelectedCategory: selected category (or None)
 * - newPostCancellationTotalTime: time spent onForm before cancelling
 * - newPostCancellationAfterError: Form was left after a validation error (num errors as value)
 * - For each element:
 *   - newPostCancellation<ElementId>InteractionTime: Total time user spent interacting with element
 *   - newPostCancellation<ElementId>NumInteractions: Number times user interacted with element
 *   - newPostCancellation<ElementId>NumCharacters: Number of characters entered (text fields only)
 */
export default class extends MetricFormController {
  static values = {
    name: String,
    dimension: String,
  };

  getMetricName(el, metricDescriptor) {
    return camelCase(`${this.nameValue}_${el.id}_${metricDescriptor}`);
  }

  getMetric(el, metricDescriptor, metricFactory) {
    let metrics = this.metrics[el.id];
    if (metrics === undefined) {
      metrics = {};
      this.metrics[el.id] = metrics;
    }

    if (metrics[metricDescriptor] !== undefined) {
      return metrics[metricDescriptor];
    }

    const metric = metricFactory(this.getMetricName(el, metricDescriptor));
    metrics[metricDescriptor] = metric;

    return metric;
  }

  getTimer(el, metricDescriptor) {
    return this.getMetric(el, metricDescriptor, (name) => {
      return this.csm().addTimer(name);
    });
  }

  getCounter(el, metricDescriptor, value = 0) {
    return this.getMetric(el, metricDescriptor, (name) => {
      return this.csm().addCounter(name).withValue(value);
    });
  }

  isTextInput(el) {
    return el.tagName === 'TEXTAREA' || el.type === 'text';
  }

  connect() {
    // all metrics collected for the form
    this.metrics = {};
    this.formElement = this.getForm().get(0);

    // monitor form and its elements to see if they were interacted with
    this.formCounter = this.getCounter(
      this.formElement,
      'Form',
      1,
    ).withDimension('InteractionType', 'NoInteraction');
    this.getFormElements().focus(() => {
      this.formCounter.withDimension('InteractionType', 'WithInteraction');
    });

    // monitor total time spent on the page
    this.getTimer(this.formElement, 'TotalTime').start();

    // check whether form gets abandoned because of errors
    const errorCount = this.getForm().find('.is-invalid').length;
    if (errorCount > 0) {
      this.getCounter(this.getForm()[0], 'AfterErrors', errorCount);
    }

    // start interaction timer and increase number of field interactions
    this.getFormElements().on('focus select2:open', (event) => {
      this.getTimer(event.target, 'InteractionTime').start();
      this.getCounter(event.target, 'NumInteractions').adjust(1);

      // also update the dimension of the last interacted element name
      this.formCounter.withDimension(
        'LastElement',
        event.target.id || camelCase(event.target.name),
      );
    });

    // stop interaction timer for previously focussed elements
    this.getFormElements().on('blur select2:close', (event) => {
      const el = event.target;
      this.getTimer(el, 'InteractionTime').stop();

      // for text elements, capture the number of characters entered
      if (this.isTextInput(el)) {
        this.getCounter(el, 'NumCharacters').value = el.value.length;
      }
    });

    // when form is submitted, therefore delete the prepared cancellation metrics
    this.getForm().submit(() => {
      Object.values(this.metrics).forEach((c) =>
        this.csm().data.deleteMetric(c),
      );
    });
  }

  disconnect() {
    // Detach event handlers
    this.getFormElements().off('focus blur select2:open select2:close');
    this.getForm().off('submit');
  }
}
